import(/* webpackMode: "eager" */ "/opt/actions-runner-2/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.69.2/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-2/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.69.2/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-2/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.69.2/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-2/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.69.2/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-2/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.69.2/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/opt/actions-runner-2/_work/io-platform-dashboard/io-platform-dashboard/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.7_@playwright+test@1.42.1_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.69.2/node_modules/next/dist/client/components/render-from-template-context.js");
